html {
  font-family: Gilroy, sans-serif !important;
  font-weight: 300;
  font-size: 100%;
  scroll-behavior: smooth;
}

* {
  font-family: Gilroy, sans-serif !important;
  color: var(--asfalt);
}

a {
  text-decoration: none !important;
  color: var(--asfalt) !important;
  font-size: 1rem !important;
}

a:hover {
  color: var(--asfalt) !important;
}

b,
strong {
  font-weight: 1000 !important;
}

strong {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  letter-spacing: 1rem;
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  h5 {
    letter-spacing: 0.15rem;
    text-decoration: underline;
    color: white !important;
    margin-top: 1rem !important;
  }
}
@media only screen and (min-width: 991px) {
  h5 {
    letter-spacing: 0.15rem;
    text-decoration: underline;
    color: black !important;
    margin-top: 1rem !important;
  }
}
@media only screen and (max-width: 576px) {
  h5 {
    letter-spacing: 0.15rem;
    text-decoration: underline;
    color: white !important;
    margin-top: 1rem !important;
  }
}

.massage h4 {
  letter-spacing: 0.25rem;
  color: white !important;
  margin: 1rem 0 !important;
}

.podminky h3 {
  letter-spacing: .35rem;
}

h2 {
  font-size: calc(1.35em + 1vw);
  text-align: center;
  padding: 0.5em 0 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
}

h2 span {
  color: #00aa80; /*IE*/
  color: var(--elgreen);
}

h3,
h1 {
  font-size: calc(1.25em + 0.6vw) !important;
  text-align: center;
  text-transform: uppercase;
}

h4 {
  font-size: calc(0.8em + 0.6vw) !important;
  text-align: center;
  text-transform: uppercase;
}

h3 span {
  color: #ed1f1f; /*IE*/
  color: var(--red);
}

@media only screen and (max-width: 576px) {
  .restaurant-page .card h4 {
    text-align: start;
    padding-top: 1rem;
    letter-spacing: 0.3rem;
  }
}

@media only screen and (max-width: 576px) {
  .restaurant-page .card p,
  .restaurant-page .card h4 {
    color: var(--white);
    text-align: start;
  }
}

p {
  text-align: left;
  /* text-align-last: center; */
  font-family: Gilroy, sans-serif;
  font-size: 1.1rem !important;
}

a span {
  font-family: Gilroy, sans-serif;
  font-weight: 300;
}

.hotel ul,
.hotel li {
  text-align: left;
  /* text-align-last: center; */
  font-family: Gilroy, sans-serif;
  font-size: 1.1rem !important;
  color: var(--white-w);
}

ol li {
  margin-top: 0.5rem;
}

ol.n {
  list-style-type: lower-alpha;
}

ol.v {
  list-style-type: none;
}

ul,
li,
ol {
  font-family: Gilroy, sans-serif;
}

h4 {
  letter-spacing: 0.25rem;
}

.nav-link {
  font-size: 0.9rem !important;
  letter-spacing: 0.1rem !important;
}

.podminky p {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.contact p {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.contact-info p {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.restaurant-page p {
  display: inline-block;
}

a {
  cursor: pointer !important;
}

sup {
  color: inherit !important;
}

p.signature {
  font-family: DancingScript, sans-serif !important;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 577px) {
  p.signature {
    font-family: DancingScript, sans-serif !important;
    margin-bottom: 3rem;
  }
}

td span,
td button {
  margin: 1rem;
}

p.name-head,
p.price {
  margin-bottom: 0.25rem !important;
}
