:root {
  --asfalt: #000;
  --white: #f1f1f1;
  --white-w: #ffffff;
  --white-hover: #ffffff;
  --green: #8d9c7d;
  --green-light: #d7dad4;
  --transparent: #ffffff00;
  --transparent-button: #ffffff88;
  --yellow: #cb8e00;
  --yellow-form: #cb8e0086;
}

.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
  margin-left: auto;
  flex: 10%;
}

.break {
  padding: 3rem;
}

div.icons {
  background-color: var(--bs-light-rgb);
}

.table > :not(caption) > * > * {
  background-color: var(--bs-light-rgb) !important;
}

.icons td div {
  color: var(--black) !important;
}

.icons td div:hover {
  color: var(--color-hex-03) !important;
}

.icon {
  cursor: pointer;
}

i:hover {
  cursor: pointer;
}

.list-img {
  width: auto;
  height: 3rem;
}

.yarl__button {
  background-color: whitesmoke !important;
  border-radius: 1rem !important;
}

.yarl__toolbar button {
  margin: 0.25rem !important;
}

.gallerylist:hover {
  cursor: pointer;
}

.gallery__image {
  background-color: var(--orange-h);
  display: inline-block;
  margin: 0 0 1em;
  position: relative;
  width: 100%;
}

p.name-head strong {
  text-transform: uppercase;
}

.CookieDeclaration {
  display: none;
}

.anchor-ref {
  color: var(--bs-link-color) !important;
  text-decoration: underline;
  text-decoration-color: var(--bs-link-color) !important;
  text-transform: lowercase;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
}

html {
  min-height: -webkit-fill-available;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

a.nav-link {
  color: var(--asfalt) !important;
}

body.login {
  background-image: url("../../assets/images/intro-bg-min.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
}

:focus-visible {
  outline: none;
}

.deactive {
  display: none;
  visibility: hidden;
}

.reservation-title {
  transform: translateY(6rem);
}

.cards-food {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transform: translateY(5rem);
}

.cards-food h1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.cards-food .card {
  flex: 1 0 33%;
  width: 18rem;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
  background-color: var(--green);
  border-radius: 0px;
  border: none;
}

.cards-food .card.domov {
  flex: 1 0 33%;
  width: 18rem;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
  background-color:rgba(0, 0, 0, 0.788);
  color:white;
  border-radius: 0px;
  border: none;
}

.surroundings-link:hover {
  color: var(--white-hover);
}

.slider-box .left-arrow ion-icon,
.slider-box .right-arrow ion-icon,
.slider-box .left-arrow-c ion-icon,
.slider-box .right-arrow-c ion-icon {
  display: none;
  visibility: hidden;
}

.slider-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform: translateY(-5rem);
  margin: 2rem;
  position: relative;
  z-index: 400;
}

.slider-container ion-icon {
  color: var(--yellow);
  position: relative;
  z-index: 400;
  transform: translateY(-0.8rem);
  height: 20px;
  width: 20px;
}

p.divider-slider {
  color: var(--yellow);
  display: inline-block;
  position: relative;
  z-index: 600;
  transform: translateY(-1rem) translateX(0.02rem);
  font-size: 1rem;
}

.slider.contact-s {
  transform: translateY(-5rem) !important;
}

.wellness-active {
  display: block;
  visibility: visible;
}

.wellness-deactive {
  margin-top: 25vh;
  margin-bottom: 10vh;
  display: none;
  visibility: hidden;
}

.slider-box .slider {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem;
  position: absolute;
  bottom: -4rem;
  z-index: 9999;
  border: none !important;
}

.slider-box {
  position: relative;
}

input:focus,
textarea:focus {
  border: 1px solid var(--yellow) !important;
  box-shadow: 0 0rem 0.5rem var(--yellow-form) !important;
  outline: 0 !important;
}

.admin-content input {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

.devider-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.devider-page h3 {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.showcase-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.showcase-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 3rem;
}

.showcase-hotel-table {
  background-color: var(--green-light);
  width: calc(100vw - 12px);
  padding-top: 1rem;
  padding-bottom: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 99.3%;
}

.showcase-wellness {
  background-color: var(--green-light);
  width: calc(100vw - 12px);
  padding-top: 1rem;
  padding-bottom: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login input {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.denied {
  background-color: var(--green) !important;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.error404 {
  background-color: var(--green) !important;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.kytka-white {
  visibility: visible;
  display: block;
  position: absolute;
  top: -50px;
  left: 60%;
  height: 500px;
  width: auto;
  z-index: 1;
  opacity: 0.45;
}

.kytka-white.thanks {
  visibility: visible;
  display: block;
  position: relative;
  top: -100px;
  left: 50%;
  height: 300px;
  transform: rotate(-23deg);
  width: auto;
  z-index: 1;
  opacity: 0.45;
}

.showcase ion-icon {
  font-size: 3.5rem;
  transition: all 0.3s;
}

.showcase ion-icon:hover {
  transition: all 0.3s;
  transform: translateY(10px);
}

.showcase h1 {
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 5rem;
}

.lang {
  cursor: pointer;
}

.hotel-row-1 {
  margin-top: 1.5rem !important;
}

.hotel-row {
  margin-top: 2rem !important;
}

.card {
  border-radius: 0px !important;
}

.card-content .card p {
  padding: 20px;
  text-align: center;
}

.card-content .card h4 {
  padding: 20px;
  font-weight: 300;
}

.card-footer-btn {
  transform: translateY(-3rem);
  display: flex;
  justify-content: center;
}

.card-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.card-body p {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 100;
  color: var(--white-w);
  padding: 0 !important;
}

.card-body p.white-card {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 100;
  color: var(--asfalt);
  padding: 0 !important;
}

.card-content .card.card-content-normal {
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
  background-color: var(--green);
  border-radius: 0px;
  transform: translateY(-1rem);
  border: none;
  width: 100%;
}

.card-content .card.card-content-normal-white {
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
  background-color: var(--white);
  border-radius: 0px;
  transform: translateY(-1rem);
  border: none;
}

.card-title {
  padding-top: 0.8rem;
}

.card .icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.well-rest-footer .icon-text {
  transform: translateY(-1.5rem);
  display: flex;
  justify-content: center;
}

.card-title h4 {
  font-size: 1.5rem;
  padding: 0 !important;
  padding-top: 1rem !important;
  color: var(--white-w);
  font-weight: 600;
}

.card-title h4.white-card {
  font-size: 1.5rem;
  padding: 0 !important;
  padding-top: 1rem !important;
  color: var(--asfalt);
  font-weight: 300;
}

footer {
  background-color: var(--white-w);
  padding: 20px;
  color: var(--asfalt);
  font-size: 1rem;
  text-align: center;
  margin-top: 3rem;
}

footer .footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer-content ion-icon {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: var(--asfalt);
  transition: all 0.3s;
  border: 0px solid var(--asfalt);
  border-radius: 50%;
}

.links {
  display: inline-block;
}
.links a {
  margin: 1rem;
}

.fb-link {
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 30px;
  height: 30px;
  color: var(--asfalt);
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
}

.fb-link a ion-icon {
  margin-left: 1rem;
  transform: translateY(0.5rem);
}

footer p {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

footer a {
  text-decoration: none;
  color: var(--asfalt);
}

.footer-logo {
  height: 150px;
  margin-bottom: 1rem;
}

.fixed-top {
  display: flex;
  flex-direction: row-reverse;
}

.cby {
  text-transform: capitalize;
}

footer .footer-text {
  font-size: 0.85rem !important;
  color: var(--asfalt);
}

.f-contact .footer-text {
  font-size: 0.85rem !important;
  color: var(--white-w);
}

.podminky {
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.contact {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.contact-info {
  margin-top: 0rem;
  margin-bottom: 3rem;
}

.contact h4 {
  color: var(--asfalt);
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.contact-info h4 {
  color: var(--asfalt);
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  text-align: start !important;
}

.contact-form {
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: var(--green);
  overflow: hidden !important;
  padding: 40px;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.contact-form h4 {
  color: var(--white-w);
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-align: start !important;
}

.contact-form .form-floating {
  padding-right: 20px;
}

.welcome-text {
  padding-left: 2rem !important;
  padding: 20px;
  position: relative;
  z-index: 100;
}

.welcome-text h3,
.welcome-text h1 {
  font-size: 150%;
  color: var(--asfalt);
  letter-spacing: 0.3rem;
  text-align: left;
  margin-bottom: 3rem;
  margin-top: 3rem;
  text-align: start;
}

.hotel-text-white span {
  font-size: 1rem;
  padding-bottom: 40px;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  transition: 0.3s;
}

.hotel-text-green span {
  color: var(--white-w);
  font-size: 1rem;
  text-align: center;
  padding-bottom: 40px;
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s;
}

.info-room-superior-active,
.info-room-standard-active,
.info-room-deluxe-active {
  display: block;
  visibility: visible;
  transition: 0.3s;
}

.superior-text-active,
.standard-text-active,
.deluxe-text-active {
  display: block;
  visibility: visible;
  transition: 0.3s;
}

.superior-text-deactive,
.standard-text-deactive,
.deluxe-text-deactive {
  display: none;
  visibility: hidden;
  transition: 0.3s;
}

.info-room-superior-deactive,
.info-room-standard-deactive,
.info-room-deluxe-deactive {
  display: none;
  visibility: hidden;
  transition: 0.3s;
}

.active {
  display: block;
  visibility: visible;
  transition: 0.3s;
}

.deactive {
  display: none;
  visibility: hidden;
  transition: 0.3s;
}

.welcome-text p:nth-child(4) {
  text-align: start;
  margin-bottom: 3rem;
}

.surrounding.welcome-text p {
  margin-bottom: 1rem !important;
  text-align: start;
  margin-bottom: 0;
}

.form-control {
  border-radius: 0 !important;
}

.rest-menu {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.rest-text,
.menu-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-text p,
.rest-text p {
  font-size: 1rem !important;
}

.menu-text .menu-text-desc {
  font-size: 0.8rem !important;
}

.rest-text .rest-text-desc {
  font-size: 0.9rem !important;
}

.hotel-text-green {
  background-color: var(--green);
  padding: 20px;
  height: auto;
  width: 100%;
  padding-left: 6rem;
  padding-right: 6rem;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.hotel-text-green p {
  color: var(--white-w) !important;
  text-align: start !important;
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.hotel-text-green ul {
  color: var(--white-w) !important;
  text-align: start !important;
  padding-bottom: 20px;
  padding-left: 55px;
  padding-right: 55px;
}

.hotel-text-green h3 {
  color: var(--white-w);
  letter-spacing: 0.3rem;
  text-align: left;
  margin-top: 3rem;
  text-align: center;
}

.hotel-text-white {
  background-color: var(--white);
  padding: 20px;
  height: auto;
  width: 100%;
  padding-left: 6rem;
  padding-right: 6rem;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.hotel-text-white p {
  color: var(--asfalt) !important;
  text-align: start !important;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 40px;
  padding-right: 40px;
}

.hotel-text-white ul {
  color: var(--white-w) !important;
  text-align: start !important;
  padding-bottom: 35px;
  padding-left: 55px;
  padding-right: 55px;
}

.hotel-text-white ul li {
  color: var(--asfalt) !important;
}

.hotel-text-white h3 {
  color: var(--asfalt);
  letter-spacing: 0.3rem;
  text-align: left;
  margin-top: 3rem;
  text-align: center;
}

.hotel-white-image {
  position: relative;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.hotel-green-image {
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.hotel-row .col,
.hotel-row .col-md-4,
.hotel-row-1 .col,
.hotel-row-1 .col-md-4 {
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.hotel-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 400;
}

.hotel {
  background: linear-gradient(
    to bottom,
    var(--green-light) 97%,
    var(--transparent) 0%
  );
  padding-top: 1rem !important;
  position: relative;
  z-index: 100;
}

.restaurant-page {
  background-color: var(--green-light);
  padding: 2rem;
}

.rest-text p {
  display: block;
}

.hotel-map {
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.hotel-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}

.hotel-header p {
  text-align: start;
}

.room-table {
  background-color: var(--green);
  padding: 20px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25) !important;
  height: auto;
  width: auto;
}

.card-button {
  background-color: var(--transparent);
  border: 1px solid var(--white-w);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  letter-spacing: 0.2rem;
  margin-top: 3rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  color: var(--white-w);
  font-weight: 300;
}

.contact-button {
  background-color: var(--transparent);
  border: 1px solid var(--white-w);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  letter-spacing: 0.2rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  color: var(--white-w);
  font-weight: 300;
}

.contact-button.disabled {
  background-color: var(--transparent);
  border: 1px solid var(--white-w);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  letter-spacing: 0.2rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  color: var(--white-w);
  font-weight: 300;
}

.rest-button {
  background-color: var(--green-light);
  border: 1px solid var(--asfalt);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  letter-spacing: 0.2rem;
  margin-top: 3rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  color: var(--asfalt);
  font-weight: 300;
}

.rest-button.admin {
  transform: translateY(3rem);
}

.admin {
  transform: translateY(6rem);
}

.admin-content {
  margin: 0;
  position: relative;
  left: 50%;
  transform: translate(-50%, 6rem);
}

.login-button {
  background-color: var(--green-light);
  border: 1px solid var(--asfalt);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  letter-spacing: 0.2rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  color: var(--asfalt);
  font-weight: 300;
  text-transform: uppercase;
  display: inline-block;
}

.restaurant-text,
.wellness-text {
  padding: 20px;
  height: 100%;
  width: 100%;
  padding-left: 6rem;
  padding-right: 6rem;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
}

.green {
  background-color: var(--green);
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.restaurant-content-others .restaurant-page.container {
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.restaurant-content-others .restaurant-page.container.wellness {
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
  padding: 3rem 5rem;
}

.restaurant-text h3,
.restaurant-text h1 {
  color: var(--asfalt);
  letter-spacing: 0.3rem;
  text-align: left;
  margin-bottom: 3rem;
  margin-top: 2rem;
  text-align: start !important;
}

.restaurant-img,
.wellness-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 400;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.wellness-text h3,
.wellness-text h1 {
  color: var(--white-w);
  letter-spacing: 0.3rem;
  text-align: left;
  margin-bottom: 3rem;
  margin-top: 2rem;
  text-align: start;
}

.restaurant-text p {
  text-align: start;
}

.wellness-text p {
  text-align: start;
  color: var(--white-w);
}

@media only screen and (min-width: 991px) {
  .restaurant-nav-item {
    position: relative;
  }

  .restaurant-nav-item .restaurant-outside {
    display: block;
    visibility: hidden;
    position: absolute;
    transition: all 0.5s;
    animation-name: navbar-hover-back;
    white-space: nowrap;
    animation-duration: 0.5s;
  }

  .restaurant-nav-item:hover .restaurant-outside {
    display: block;
    visibility: visible;
    background-color: var(--transparent-button);
    animation-name: navbar-hover;
    animation-duration: 0.5s;
    white-space: nowrap;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .arrow-navlink {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow-navlink ion-icon {
    transform: translateY(0.1rem) translateX(0.1rem);
  }

  .navbar-color .restaurant-nav-item:hover .restaurant-outside {
    display: block;
    visibility: visible;
    background-color: var(--white-w);
    animation-name: navbar-hover;
    animation-duration: 0.5s;
    white-space: nowrap;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
  }

  @keyframes navbar-hover {
    from {
      transform: translateX(0) translateY(-2rem);
      opacity: 0;
    }
    to {
      transform: translateX(0) translateY(0rem);
      opacity: 1;
    }
  }

  @keyframes navbar-hover-back {
    from {
      transform: translateX(0) translateY(0rem);
      opacity: 1;
    }
    to {
      transform: translateX(0) translateY(-2rem);
      opacity: 0;
    }
  }

  .welcome {
    position: relative;
    max-width: 1920px;
  }

  .restaurant-content-mobile {
    display: none;
    visibility: hidden;
  }

  .hotel-white-image span.top {
    background-color: var(--yellow);
    color: var(--white-w);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
    height: 60px;
    width: 130px;
    z-index: 500;
    position: absolute;
    right: -5rem;
    top: 2rem;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
    visibility: visible !important;
  }

  .hotel-white-image span.ribbon {
    background-color: var(--yellow);
    color: var(--white-w);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
    height: 60px;
    width: 130px;
    z-index: -100;
    position: absolute;
    right: -4.8rem;
    top: -1.4rem;
    visibility: visible !important;
    transform: rotate(45deg);
    overflow: hidden !important;
  }

  .hotel-white-image span.transparent {
    background-color: var(--green-light);
    color: var(--white-w);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
    height: 100px;
    width: 140px;
    z-index: -50;
    position: absolute;
    right: -2.5rem;
    top: -4rem;
    visibility: visible !important;
  }

  .contact-form-flex {
    display: flex;
    align-items: center;
    position: relative;
  }

  .restaurant-page h3,
  .restaurant-page h1 {
    text-align: start;
    padding-left: 2rem;
    padding-bottom: 2rem;
    letter-spacing: 0.3rem;
  }

  .restaurant-page h3 {
    padding-bottom: 0rem !important;
  }

  .restaurant-page h4 {
    text-align: start;
    padding-left: 2rem;
    padding-bottom: 0.5rem;
    text-decoration: underline;
  }

  .rest-text {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .hotel,
  .contact-page,
  .restaurant-page {
    margin-top: 4rem;
  }

  .card-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .hotel-row,
  .hotel-row-1 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .reservation {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .reservation-button {
    background-color: var(--transparent);
    border: 1px solid var(--white-w);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    letter-spacing: 0.2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    color: var(--white-w);
    font-weight: 300;
  }

  .input-field {
    background-color: var(--white);
    border: none;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    letter-spacing: 0.2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    font-weight: 700;
    text-transform: uppercase;
  }

  .input-field span {
    color: #00000046 !important;
  }

  .reservation-table {
    position: absolute;
    bottom: -4rem;
    padding: 40px;
    background-color: var(--green);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5) !important;
    object-fit: cover;
    min-width: 1280px;
    max-width: 1440px;
  }

  .contact-photo {
    visibility: visible;
    display: block;
    width: 100%;
    height: calc(100% - 6rem);
    margin-top: 3rem;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
    object-fit: cover;
  }

  a.anchor {
    display: block;
    position: relative;
    top: -50px;
    visibility: hidden;
  }

  .podminky a {
    color: blue;
  }

  .room-table h4 {
    padding: 20px;
    color: var(--white-w);
  }

  .room-table p {
    padding: 20px;
    padding-right: 70px;
    text-align: start;
    color: var(--white-w);
  }

  .room {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .room-images {
    width: 105%;
    height: 110%;
    object-fit: cover;
    transform: translateX(-50px) translateY(-2px);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25) !important;
  }

  .room-img-slider {
    position: relative;
    display: flex;
    align-items: center;
  }

  .helpdesk-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .helpdesk-form .contact-form {
    width: 50%;
  }

  .edelweiss-top {
    background-color: var(--green-light);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .hamburger-react {
    visibility: hidden;
    display: none;
  }

  .welcome-text {
    margin-bottom: 2rem;
  }

  .footer-items p {
    display: inline-block !important;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: var(--asfalt) !important;
  }

  .f-contact .footer-items p {
    display: inline-block !important;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: var(--white-w) !important;
  }

  .well-rest {
    position: relative;
    max-width: 1920px;
  }

  .card img {
    overflow: hidden;
    height: 250px;
    width: auto;
    object-fit: cover;
  }

  .intro-logo {
    height: 500px;
  }

  .footer-content ion-icon:hover {
    transform: translateY(-5px);
    transition: all 0.3s;
    border: 0px solid var(--asfalt);
    border-radius: 50%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .fb-link img:hover {
    transform: translateY(-5px);
    transition: all 0.3s;
    border: 0px solid var(--asfalt);
    border-radius: 50%;
  }

  .footer-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }

  .top-nav {
    background-color: var(--transparent) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
    flex-grow: 1;
    transition: 0.3s;
    visibility: visible;
  }

  .navbar-nav {
    display: flex;
    flex-direction: row !important;
    visibility: visible !important;
  }

  .navbar-color {
    background-color: #ffffff !important;
    transition: 0.3s;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .nav-link {
    padding: 0.3rem !important;
  }

  .right .nav-link {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  .center,
  .right,
  .left {
    display: flex;
  }

  .right {
    position: absolute;
    right: 0;
    padding-right: 2rem;
  }

  .left {
    position: absolute;
    top: 0;
    margin-top: 1.25rem;
    left: 0;
    padding-left: 2rem;
  }

  .nav-link {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }

  .navbar-navll {
    font-size: 1rem !important;
    margin-top: 0.15rem;
    color: var(--asfalt);
    visibility: visible !important;
  }

  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: var(--asfalt);
  }

  .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--asfalt);
    transform-origin: center;
    transition: transform 0.15s ease-out;
  }

  .f-contact .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--white-w);
    transform-origin: center;
    transition: transform 0.15s ease-out;
  }

  .login-form {
    width: 600px !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25) !important;
  }

  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: center;
  }

  footer.f-contact {
    background-color: var(--green);
    padding: 20px;
    color: var(--white-w);
    font-size: 1rem;
    text-align: center;
    padding-top: 3rem;
  }

  .card-button:hover,
  .contact-button:hover,
  .reservation-button:hover {
    color: var(--white);
    border: 1px solid var(--yellow);
    transition: all 0.3s;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.25) !important;
  }

  .card-button.disabled:hover {
    color: var(--black);
    border: 1px solid var(--black);
    transition: all 0.3s;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  }

  .contact-button.disabled:hover {
    color: var(--white);
    border: 1px solid var(--white);
    transition: all 0.3s;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  }

  .rest-button:hover,
  .login-button:hover {
    color: var(--white-w);
    border: 1px solid var(--asfalt);
    background-color: var(--green);
    transition: all 0.3s;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.25) !important;
  }

  .cards {
    background: linear-gradient(
      to bottom,
      var(--green-light) 90%,
      var(--transparent) 0%
    );
    transform: translateY(-3rem);
    margin-top: 5rem !important;
  }

  .cards.gallery {
    background: linear-gradient(
      to bottom,
      var(--green-light) 100%,
      var(--transparent) 0%
    );
    transform: translateY(-3rem);
    margin-top: 5rem !important;
  }

  .welcome span {
    background-color: var(--yellow);
    height: 60px;
    width: 35px;
    position: absolute;
    margin-top: 7rem;
    left: 0;
  }

  .restaurant-text span {
    background-color: var(--yellow);
    height: 60px;
    width: 35px;
    position: absolute;
    left: 0;
  }

  .reservation-intro-mobile,
  .reservation-table-mobile,
  .reservation-mobile,
  .input-field-mobile {
    display: none;
    visibility: hidden;
  }

  .akce-vecery {
    margin-top: 3rem !important;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    background-image: url("../../assets/images/christmas.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3rem;
    width: 100%;
  }

  .akce-vecery-sil {
    margin-top: 3rem !important;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    background-color: #b57f28;
    background-image: url("../../assets/images/silvestr.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3rem;
    width: 100%;
  }

  .akce-vecery-sil h2 {
    color: var(--white-w) !important;
  }

  .showcase {
    background-image: url("../../assets/images/jested-min.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-december {
    background-image: url("../../assets/images/newyear2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-hotel {
    background-image: url("../../assets/images/deluxe/_30A5987.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-hotelres {
    background-color: var(--green-light);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-surroundings {
    background-image: url("../../assets/images/city-glider-min.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-restaurant {
    background-image: url("../../assets/images/restaurant-min.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-actions {
    background-image: url("../../assets/images/actions.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-garden-restaurant {
    background-image: url("../../assets/images/garden-rest-min.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4rem;
  }

  .showcase-wellness {
    background-image: url("../../assets/images/wellness.JPG");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .restaurant-text span {
    background-color: var(--yellow);
    height: 50px;
    width: 35px;
    position: absolute;
    right: 0;
  }

  .cards.gallery {
    background: transparent;
  }

  .break {
    padding: 4rem;
  }

  .akce-vecery {
    margin-top: 3rem !important;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    background-color: #b57f28;
    background-image: url("../../assets/images/christmas.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3rem;
    width: 100%;
  }

  footer.f-contact {
    background-color: var(--green);
    padding: 20px;
    color: var(--white-w);
    font-size: 1rem;
    text-align: center;
    padding-top: 3rem;
  }

  .helpdesk-form .contact-form {
    width: 80%;
  }

  .akce-vecery,
  .akce-vecery-sil {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }

  .akce-vecery .rest-button,
  .akce-vecery .rest-button {
    margin: 1rem;
  }

  .showcase {
    background-image: url("../../assets/images/jested-min.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: initial !important;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-december {
    background-image: url("../../assets/images/newyear2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: initial !important;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-surroundings {
    background-image: url("../../assets/images/city-glider-min.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-attachment: initial !important;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-hotel {
    background-color: var(--green-light);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-attachment: initial !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-restaurant {
    background-image: url("../../assets/images/restaurant-min.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-attachment: initial !important;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-garden-restaurant {
    background-image: url("../../assets/images/garden-rest-min.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-attachment: initial !important;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4rem;
  }

  .showcase-wellness {
    background-image: url("../../assets/images/wellness.JPG");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-attachment: initial !important;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .slider-box .slider {
    display: none;
    visibility: hidden;
  }

  .slider-c,
  .slider-container {
    display: none;
    visibility: hidden;
  }

  .slider-box .right-arrow ion-icon {
    position: absolute;
    right: 0;
    top: 48%;
    z-index: 500;
    height: 35px;
    width: 35px;
    color: var(--yellow);
    display: block !important;
    visibility: visible !important;
  }

  .slider-box .left-arrow ion-icon {
    position: absolute;
    left: 0;
    top: 48%;
    z-index: 500;
    height: 35px;
    width: 35px;
    color: var(--yellow);
    display: block !important;
    visibility: visible !important;
  }

  .slider-box .right-arrow-c ion-icon {
    position: absolute;
    right: 1rem;
    top: 40%;
    z-index: 500;
    height: 35px;
    width: 35px;
    color: var(--yellow);
    display: block !important;
    visibility: visible !important;
  }

  .slider-box .left-arrow-c ion-icon {
    position: absolute;
    left: 1rem;
    top: 40%;
    z-index: 500;
    height: 35px;
    width: 35px;
    color: var(--yellow);
    display: block !important;
    visibility: visible !important;
  }

  .welcome span {
    background-color: var(--yellow);
    height: 50px;
    width: 35px;
    position: absolute;
    margin-top: 7rem;
    right: 0;
  }

  .kytka-white {
    display: block;
    visibility: visible;
    position: absolute;
    top: 50px;
    left: 30%;
    height: 450px;
    opacity: 0.45;
  }

  .kytka-white.thanks {
    display: block;
    visibility: visible;
    position: absolute;
    top: 100px;
    left: 10%;
    height: 450px;
    opacity: 0.45;
  }

  .reservation-intro-mobile,
  .reservation-table-mobile,
  .reservation-mobile,
  .input-field-mobile {
    display: block;
    visibility: visible;
  }

  .hotel,
  .contact-page,
  .restaurant-page {
    margin-top: 3rem;
  }

  .restaurant-content-mobile {
    display: none;
    visibility: hidden;
  }

  .restaurant-page {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    background-color: var(--green);
  }

  .reservation-intro,
  .reservation-table,
  .reservation,
  .input-field,
  .reservation-button {
    display: none;
    visibility: hidden;
  }

  .contact-photo {
    visibility: visible;
    display: block;
    width: 100%;
    height: calc(100% - 6rem);
    margin-top: 3rem;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
    object-fit: cover;
  }

  .hotel {
    margin-top: 0 !important;
  }

  .well-rest {
    position: relative;
    margin-top: 3rem;
  }

  .reservation {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .reservation-intro {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .reservation-table-mobile {
    padding: 20px;
    background-color: var(--white);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    object-fit: cover;
    margin-bottom: 4rem;
    margin-top: 3rem;
  }

  .reservation-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .reservation-button-mobile {
    width: 100%;
    background-color: var(--green-light);
    border: 1px solid var(--asfalt);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    letter-spacing: 0.2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    color: var(--asfalt);
    font-weight: 300;
    margin: 1rem;
  }

  .input-field-mobile {
    background-color: var(--white);
    border: none;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 7rem;
    padding-right: 7rem;
    letter-spacing: 0.2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid var(--asfalt);
  }

  .input-field-mobile span {
    color: #00000046 !important;
  }

  .input-field-mobile {
    margin: 1rem;
    width: 100%;
  }

  .card-content .card.card-content-normal {
    transform: translateY(-1rem);
  }

  .cards {
    background: linear-gradient(
      to bottom,
      var(--green-light) 97%,
      var(--transparent) 0%
    );
    transform: translateY(2rem);
  }

  .welcome-text {
    padding-left: 2.5rem;
  }

  .welcome-text.hotel-text p {
    text-align: start;
    margin-bottom: 1rem;
  }

  .restaurant-text,
  .wellness-text {
    padding: 1rem !important;
  }

  .hotel-row,
  .hotel-row-1 {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .edelweiss-top {
    background-color: var(--green-light);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .room-table-dark h4 {
    font-weight: 700;
    padding: 20px;
  }

  .room-table-dark p {
    padding: 20px;
    text-align: start;
  }

  .room-table-light h4 {
    font-weight: 700;
    padding: 20px;
  }

  .room-table-light p {
    padding: 20px;
    text-align: start;
  }

  .room {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .room-images {
    width: 100%;
    height: 110%;
    object-fit: cover;
  }

  .room-img-slider {
    position: relative;
    display: flex;
    align-items: center;
  }

  .navbar-navll {
    font-size: 1rem !important;
    margin-top: 0.15rem;
    color: var(--asfalt);
    visibility: hidden !important;
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-direction: row !important;
    visibility: hidden !important;
  }

  .top-nav {
    visibility: hidden !important;
  }

  .wellness-text,
  .restaurant-text {
    padding: 30px;
  }

  .intro-logo {
    height: 450px;
  }

  .hamburger-react {
    position: fixed !important;
    top: 0;
    right: 0;
    z-index: 9999;
    margin-top: 1rem;
    margin-right: 1.5rem;
    visibility: visible !important;
    display: block;
    transition: all 0.3s;
  }

  .logo-hamb {
    position: fixed !important;
    left: 0;
    z-index: 9999;
    margin-top: 1rem;
    margin-right: 1.5rem;
    visibility: visible !important;
    display: block;
    transition: all 0.3s;
  }

  .active .hamburger-react div {
    transition: all 0.3s;
  }

  .footer-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .hotel-row span {
    font-size: 1rem;
    letter-spacing: 0.15rem;
  }

  .card img {
    overflow: hidden;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .contact-page {
    margin-top: 3rem;
  }

  .showcase-hotel-table {
    padding-bottom: 1rem;
  }

  .restaurant-page h3,
  .restaurant-page h1 {
    text-align: start;
    padding-bottom: 2rem;
    letter-spacing: 0.3rem;
  }

  .restaurant-page h3 {
    padding-bottom: 0rem !important;
  }

  .restaurant-page h4 {
    text-align: start;
    padding-bottom: 1rem;
    text-decoration: underline;
  }

  .restaurant-page p,
  .restaurant-page h3,
  .restaurant-page h1,
  .restaurant-page h4 {
    color: var(--white-w);
  }

  .rest-menu {
    margin-top: 3rem;
  }

  .hotel-white-image span.top {
    background-color: var(--yellow);
    color: var(--white-w);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
    height: 60px;
    width: 90px;
    z-index: 500;
    position: absolute;
    right: -1rem;
    top: 2rem;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
    visibility: visible !important;
  }

  .hotel-white-image span.ribbon {
    display: none;
    visibility: hidden !important;
  }

  .hotel-white-image span.transparent {
    display: none;
    visibility: hidden !important;
  }

  .card-body {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (min-width: 576px) {
  footer.f-contact {
    background-color: var(--green) !important;
    padding: 20px;
    color: var(--white-w);
    font-size: 1rem;
    text-align: center;
    padding-top: 3rem;
  }

  .akce-vecery {
    margin-top: 3rem !important;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    background-color: #b57f28;
    background-image: url("../../assets/images/christmas.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3rem;
    width: 100%;
  }

  .akce-vecery-sil {
    margin-top: 3rem !important;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    background-color: #b57f28;
    background-image: url("../../assets/images/silvestr.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3rem;
    width: 100%;
  }

  div#cover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
    transition: all 0.5s;
  }

  .helpdesk-form .contact-form {
    width: 80%;
  }

  div#cover.covering {
    display: block;
    height: 100%;
    width: 100%;
    z-index: 600;
    background-color: rgba(0, 0, 0, 0.432);
    transition: all 0.5s;
  }
}

@media only screen and (max-width: 576px) {
  .price strong {
    color: white;
  }

  .restaurant-text .price strong {
    color: var(--asfalt);
  }

  .break {
    padding: 4rem;
  }

  .akce-vecery {
    margin-top: 3rem !important;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    background-color: #b57f28;
    background-image: url("../../assets/images/christmas.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3rem;
    width: 100%;
    margin-bottom: 3rem;
  }

  .akce-vecery-sil {
    margin-top: 3rem !important;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    background-color: #b57f28;
    background-image: url("../../assets/images/silvestr.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3rem;
    width: 100%;
    margin-bottom: 3rem;
  }

  footer.f-contact {
    background-color: var(--green);
    padding: 20px;
    color: var(--white-w);
    font-size: 1rem;
    text-align: center;
    padding-top: 3rem;
  }

  .helpdesk-form .contact-form {
    width: 90%;
  }

  .reservation-intro-mobile,
  .reservation-table-mobile,
  .reservation-mobile,
  .input-field-mobile {
    display: block;
    visibility: visible;
  }

  .reservation-intro,
  .reservation-table,
  .reservation,
  .input-field,
  .reservation-button {
    display: none;
    visibility: hidden;
  }

  .contact-photo {
    visibility: visible;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 3rem;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
    object-fit: cover;
  }

  .akce-vecery,
  .akce-vecery-sil {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }

  .akce-vecery .rest-button,
  .akce-vecery-sil .rest-button {
    margin: 1rem;
  }

  .showcase {
    background-image: url("../../assets/images/jested-min.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: initial !important;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .hotel {
    margin-top: 0 !important;
  }

  .well-rest {
    position: relative;
  }

  .reservation {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .reservation-intro {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .reservation-table-mobile {
    padding: 20px;
    background-color: var(--white);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    object-fit: cover;
    margin-bottom: 5rem;
  }

  .reservation-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .reservation-button-mobile {
    width: 100%;
    background-color: var(--green-light);
    border: 1px solid var(--asfalt);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    letter-spacing: 0.2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    color: var(--asfalt);
    font-weight: 300;
    margin: 1rem;
  }

  .input-field-mobile {
    background-color: var(--white);
    border: none;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 7rem;
    padding-right: 7rem;
    letter-spacing: 0.2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid var(--asfalt);
  }

  .input-field-mobile span {
    color: #00000046 !important;
  }

  .input-field-mobile {
    margin: 1rem;
    width: 100%;
  }

  .card-content .card.card-content-normal {
    transform: translateY(0rem);
  }

  .cards {
    background: linear-gradient(
      to bottom,
      var(--green-light) 97.5%,
      var(--transparent) 0%
    );
  }

  .welcome-text {
    padding: 0;
    padding-left: 0 !important;
  }

  .welcome-text.hotel-text p {
    text-align: start;
    margin-bottom: 1rem;
  }

  .restaurant-text,
  .wellness-text {
    padding: 1rem !important;
  }

  .hotel-row,
  .hotel-row-1 {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .kytka-white {
    display: none;
    visibility: hidden;
  }

  .kytka-white.thanks {
    display: block;
    visibility: visible;
  }

  .edelweiss-top {
    background-color: var(--green-light);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .room-table-dark h4 {
    font-weight: 700;
    padding: 20px;
  }

  .room-table-dark p {
    padding: 20px;
    text-align: start;
  }

  .room-table-light h4 {
    font-weight: 700;
    padding: 20px;
  }

  .room-table-light p {
    padding: 20px;
    text-align: start;
  }

  .room {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .room-images {
    width: 100%;
    height: 110%;
    object-fit: cover;
  }

  .room-img-slider {
    position: relative;
    display: flex;
    align-items: center;
  }

  .navbar-navll {
    font-size: 1rem !important;
    margin-top: 0.15rem;
    color: var(--asfalt);
    visibility: hidden !important;
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-direction: row !important;
    visibility: hidden !important;
  }

  .top-nav {
    visibility: hidden !important;
  }

  .wellness-text,
  .restaurant-text {
    padding: 30px;
  }

  .intro-logo {
    height: 300px;
  }

  .hamburger-react {
    position: fixed !important;
    top: 0;
    right: 0;
    z-index: 9999;
    margin-top: 1rem;
    margin-right: 1.5rem;
    visibility: visible !important;
    display: block;
    transition: all 0.3s;
  }

  .active .hamburger-react div {
    transition: all 0.3s;
  }

  .footer-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .hotel-row span {
    font-size: 1rem;
    letter-spacing: 0.15rem;
  }

  .card img {
    overflow: hidden;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .contact-page {
    margin-top: 5rem;
  }

  .showcase-hotel,
  .showcase-garden-restaurant,
  .showcase-wellness,
  .showcase-restaurant,
  .showcase-surroundings,
  .showcase-december {
    background-image: none !important;
    background-color: var(--green-light) !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .showcase-hotel-table {
    padding-bottom: 1rem;
  }

  .restaurant-page {
    position: relative;
    z-index: 400;
    padding: 1rem;
    background: linear-gradient(
      to bottom,
      var(--green-light) 90%,
      var(--transparent) 0%
    );
  }

  .restaurant-page .card {
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
    background-color: var(--green);
    padding-top: 1rem;
    transform: translateY(-2rem);
  }

  .restaurant-page .card h3 {
    text-align: start;
    padding-bottom: 2rem;
    letter-spacing: 0.3rem;
  }

  .restaurant-page .card p,
  .restaurant-page .card h3 {
    color: var(--white);
    text-align: start;
  }

  .hotel-white-image span.top {
    background-color: var(--yellow);
    color: var(--white-w);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
    height: 60px;
    width: 90px;
    z-index: 500;
    position: absolute;
    right: -0.75rem;
    top: 2rem;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
    visibility: visible !important;
  }

  .hotel-white-image span.ribbon {
    display: none;
    visibility: hidden !important;
  }

  .hotel-white-image span.transparent {
    display: none;
    visibility: hidden !important;
  }

  .card-body {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .rest-menu {
    margin-top: -2rem !important;
  }

  .restaurant-content-others {
    display: none;
    visibility: hidden;
  }

  .slider-box .slider {
    display: none;
    visibility: hidden;
  }

  .slider-c,
  .slider-container {
    display: none;
    visibility: hidden;
  }

  .welcome span {
    background-color: var(--yellow);
    height: 60px;
    width: 35px;
    position: absolute;
    margin-top: 3rem;
    right: 0;
  }

  p.divider-slider,
  .slider-container ion-icon {
    display: none;
    visibility: hidden;
  }

  .mobile-off {
    display: none;
    visibility: hidden;
  }

  .slider-box .right-arrow ion-icon {
    position: absolute;
    right: 0;
    top: 48%;
    z-index: 500;
    height: 30px;
    width: 30px;
    color: var(--yellow);
    display: block !important;
    visibility: visible !important;
  }

  .cards.gallery {
    background: transparent;
  }

  .slider-box .left-arrow ion-icon {
    position: absolute;
    left: 0;
    top: 48%;
    z-index: 500;
    height: 30px;
    width: 30px;
    color: var(--yellow);
    display: block !important;
    visibility: visible !important;
  }

  .slider-box .right-arrow-c ion-icon {
    position: absolute;
    right: 1rem;
    top: 40%;
    z-index: 500;
    height: 30px;
    width: 30px;
    color: var(--yellow);
    display: block !important;
    visibility: visible !important;
  }

  .slider-box .left-arrow-c ion-icon {
    position: absolute;
    left: 1rem;
    top: 40%;
    z-index: 500;
    height: 30px;
    width: 30px;
    color: var(--yellow);
    display: block !important;
    visibility: visible !important;
  }

  .contact-arrow {
    position: relative;
  }

  .restaurant-text span {
    background-color: var(--yellow);
    height: 40px;
    width: 30px;
    position: absolute;
    right: 0;
  }

  .grill,
  .surroundings {
    transform: translateY(2rem);
  }
}

.rest-button.valentine {
  background-color: whitesmoke;
  color: black;
}

.rest-button.valentine:hover {
  background-color: #202c28;
  color: white;
}

.akce-vecery h2 {
  color: white;
  font-weight: 1000;
  text-shadow: 1px 1px 2px black;
}

.akce-vecery p {
  color: white;
  text-shadow: 1px 1px 2px black;
}

.akce-vecery-sil h2 {
  color: white;
  font-weight: 1000;
  text-shadow: 1px 1px 2px black;
}

.akce-vecery-sil p {
  color: white;
  text-shadow: 1px 1px 2px black;
}

.logo-hamb {
  position: fixed !important;
  left: 0;
  top: 0;
  z-index: 9999;
  margin-top: 1rem;
  margin-left: 1.5rem;
  visibility: visible !important;
  display: block;
  transition: all 0.3s;
}

@media only screen and (max-width: 991px) {
  .logo-hamb.mob.active {
    position: fixed !important;
    left: 0;
    top: 0;
    z-index: 9999;
    margin-left: 1.5rem;
    visibility: visible !important;
    display: block;
    transition: all 0.3s;
  }
  .logo-hamb.mob {
    position: fixed !important;
    left: 0;
    top: 0;
    z-index: 9999;
    margin-left: 1.5rem;
    visibility: visible !important;
    display: block;
    transition: all 0.3s;
    display: none;
    visibility: hidden;
    transition: 0.3s;
  }

  .nav-mob {
    display: none;
    transition: 0.3s;
  }

  .nav-mob.active {
    display: block;
    background-color: var(--white-w);
    width: 100vw;
    height: 5.25rem;
    position: absolute;
    top: 0;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5) !important;
    transition: 0.3s;
  }

  .nav-mob.active.hamb {
    display: none !important;
    transition: 0.3s;
  }
}

.logo-hamb.mob {
  display: none;
  visibility: hidden;
  transition: 0.3s;
}

.cards-food.qr h1 {
  font-size: 1.5rem !important;
  letter-spacing: 0.25rem;
}
