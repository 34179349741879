@media only screen and (min-width: 992px) {
  .sidebar {
    display: none;
    visibility: hidden !important;
  }
  .sidebar.active {
    display: none;
    visibility: hidden !important;
  }
}

@media only screen and (max-width: 991px) {
  .sidebar {
    display: block;
    visibility: hidden !important;
    position: fixed;
    top: 0;
    z-index: -100;
    right: 0;
    height: 100%;
    transition: 0.3s;
    animation: fade-in-move-left 0.3s;
    visibility: hidden;
  }

  .sidebar.active {
    height: 100%;
    width: 25rem;
    position: fixed;
    display: block;
    visibility: visible !important;
    top: 0;
    z-index: 999;
    right: 0;
    background-color: var(--green);
    transition: 0.3s;
    animation: fade-in-move-right 0.3s;
    visibility: visible;
  }

  .sidebar-list {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    margin-top: 150px;
    text-align: center;
  }

  .sidebar-item {
    margin: 30px 0;
    letter-spacing: 0.2rem;
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  .sidebar-item.active {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }

  .sidebar-anchor:hover:before {
    width: 100%;
  }

  .sidebar-item a {
    text-decoration: none;
    color: #fff;
    font-size: 1.25rem;
  }

  .sidebar-list .nav-item .nav-link {
    font-size: 1.25rem !important;
    color: var(--white) !important;
  }

  .devider {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .devider-sidebar {
    width: 50%;
    text-align: center;
    color: var(--white-w);
    height: 2px !important;
  }
}

@media only screen and (max-width: 600px) {
  .sidebar.active {
    height: 100%;
    width: 100% !important;
    position: fixed;
    top: 0;
    z-index: 999;
    right: 0;
    background-color: var(--green);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    visibility: visible !important;
  }

  .sidebar-list {
    margin-top: 0;
  }
}

@keyframes fade-in-move-right {
  0% {
    opacity: 0;
    transform: translateX(25rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-move-left {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(25rem);
  }
}
