.loading {
  background-color: white;
  height: 100%;
  width: 100%;
}

.logo_loading {
  width: 100px;
  animation: rotation 2s infinite linear;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--yellow);
  color: var(--yellow);
  box-shadow: 9984px 0 0 0 var(--yellow), 9999px 0 0 0 var(--yellow), 10014px 0 0 0 var(--yellow);
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 var(--yellow), 9999px 0 0 0 var(--yellow), 10014px 0 0 0 var(--yellow);
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 var(--yellow), 9999px 0 0 0 var(--yellow), 10014px 0 0 0 var(--yellow);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 var(--yellow), 9999px 0 0 0 var(--yellow), 10014px 0 0 0 var(--yellow);
  }
  50% {
    box-shadow: 9984px 0 0 0 var(--yellow), 9999px -10px 0 0 var(--yellow), 10014px 0 0 0 var(--yellow);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 var(--yellow), 9999px 0 0 0 var(--yellow), 10014px 0 0 0 var(--yellow);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 var(--yellow), 9999px 0 0 0 var(--yellow), 10014px -10px 0 0 var(--yellow);
  }
  100% {
    box-shadow: 9984px 0 0 0 var(--yellow), 9999px 0 0 0 var(--yellow), 10014px 0 0 0 var(--yellow);
  }
}